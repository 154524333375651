// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia("(max-width:768px)").matches && device === "sp") {
    return true;
  } else if (
    window.matchMedia("(min-width:769px)").matches &&
    device === "pc"
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function lazyLoadVideo() {
  var videoPc = document.getElementById("videoPc");

  var xhr = new XMLHttpRequest();
  xhr.open("GET", "./img/pc.mp4", true);
  xhr.responseType = "blob";
  console.log(xhr);
  xhr.onload = function(e) {
    if (this.status === 200) {
      console.log("videoPc ロード完了");
      // ロード完了
      var blob = this.response;
      var src = (window.webkitURL || window.URL).createObjectURL(blob);
    }
    console.log("videoPc loaded!!!");
    videoPc.src = "./img/pc.mp4";
    videoPc.play();
  };
  xhr.send();
}

function topPageFunc() {
  if (mq("sp")) {
    var topSwiper = new Swiper("#sliderWrapFirst", {
      effect: "fade",
      speed: 3000,
      pagination: {
        el: ".swiper-pagination"
      },
      autoplay: {
        delay: 4000
      },
      loop: true
    });
  } else {
    lazyLoadVideo();
  }
}

function subPageFunc() {
  if ($("#salonPage").length) {
    $(".slider-for").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: ".slider-nav"
    });

    $(".slider-nav").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      asNavFor: ".slider-for",
      centerPadding: "0px",
      dots: false,
      centerMode: true,
      focusOnSelect: true
    });
  }
}
function interSectiionObserveFunc() {
  var observer = lozad(".lozad", {
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add("loaded");
    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});
}
// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on("load", function() {});

// --- リサイズが走った場合 ----------------------------------------
$(window).on("resize", function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log("script.js");
  interSectiionObserveFunc();
  topPageFunc();
  // SMOOTH SCROOL ATTRIBUTE NAME
  $('a[href^="#"]').click(function() {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? "html" : href);
    var position = target.offset().top;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
  // MENU
  $("#menuTrigger").click(function() {
    if ($("body").hasClass("menu-show")) {
      $("body").removeClass("menu-show");
    } else {
      $("body").addClass("menu-show");
    }
  });
  subPageFunc();
  // PC or SPサンプル
  if (mq("sp")) {
    console.log("Mobile");
  } else {
    console.log("Desktop");
  }
});

// SCROLL AND TOCH START ANIMATION
$(window).on("touchstart scroll", function() {});
